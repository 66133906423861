<template>
  <div class="accordion mt-3 mb-3" id="accordionFlushExample" v-if="!loading">
    <div
      class="accordion-item"
      v-for="article in articles"
      v-bind:key="article"
    >
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed"
          :class="[
            'accordion-button',
            globalTextColor == '#000000'
              ? 'accordion-chevron-dark'
              : 'accordion-chevron-light',
          ]"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#article-' + article._id"
        >
          {{ article.title }}
        </button>
      </h2>
      <div
        :id="'article-' + article._id"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionFlushExample"
      >
        <div class="accordion-body" v-html="article.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ArticlesComponent",
  props: {
    articles: Array,
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
  },
};
</script>
<style scoped>
.btn-globalcolor,
.btn-globalcolor:after,
.btn-globalcolor:active,
.btn-globalcolor:visited {
  background-color: v-bind(globalColor) !important;
  color: v-bind(globalTextColor) !important;
}
.btn-globalcolor:hover {
  opacity: 0.5;
}
.accordion-button {
  background-color: v-bind(globalColor) !important;
  color: v-bind(globalTextColor) !important;
}
.accordion-chevron-dark::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000' %3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
.accordion-chevron-light::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff' %3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
::v-deep(.accordion-body img) {
  max-width: 100%;
  height: auto;
  display: block;
}
</style>
