<!-- Page Hero Component -->
<template>
  <div
    class="container pull-up bg-globalcolor p-0"
    v-if="pageContent && !loading"
  >
    <div class="row">
      <div
        class="col-md-6 text-light pt-5 pe-5 ps-5 pb-3 mt-5 order-2 order-lg-1"
      >
        <div class="text-container">
          <h1>{{ pageContent.title }}</h1>
          <div
            v-if="pageContent.content && pageContent.content.length"
            class="lead"
            v-html="pageContent.content[0]"
            :style="{ '--global-text-color': globalTextColor }"
          ></div>
        </div>
      </div>
      <div class="col-md-6 order-1 order-lg-2">
        <ImageCarousel :images="pageContent.images" ratio="1x1" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageCarousel from "./ImageCarousel.vue";

export default {
  name: "pageContentHero",
  components: {
    ImageCarousel,
  },
  props: {
    pageContent: Object,
  },
  computed: {
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  methods: {
    getImgUrl() {
      return process.env.VUE_APP_IMAGEURL;
    },
  },
};
</script>

<style scoped>
.ratio img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Push content to the bottom */
  height: 100%; /* Ensure container has a height */
  color: v-bind(globalTextColor) !important;
}

.bg-globalcolor {
  background-color: v-bind(globalColor);
}
</style>
