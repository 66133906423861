import { createI18n } from "vue-i18n";
import en from "@/locales/en.json";
import nl from "@/locales/nl.json";

// get the lang from local storage
// Cant import store te prevent loop
const vuexState = localStorage.getItem("vuex");

let lang = "nl";

if (vuexState) {
  try {
    // Parse the JSON string into an object
    const parsedState = JSON.parse(vuexState);
    lang = parsedState.lang;
    // // Access the "lang" property
    // console.log(parsedState.lang);
  } catch (error) {
    console.error("Error parsing Vuex state:", error);
  }
} else {
  console.log("No Vuex state found in localStorage.");
}

const i18n = createI18n({
  locale: lang, // Default language
  fallbackLocale: "nl",
  messages: {
    en,
    nl,
  },
});

export default i18n;
