<template>
  <a
    v-for="tag in sortedTags"
    v-bind:key="tag"
    class="badge badge-globalcolor me-2 mb-2"
    @click="gotoFilteredProducts(getCleanTag(tag))"
  >
    {{ getCleanTag(tag) }}
  </a>
</template>
<script>
export default {
  name: "TagComponent",
  props: {
    tags: Array,
  },
  computed: {
    sortedTags() {
      const cleanTags = this.tags.map((tag) => this.getCleanTag(tag));
      return [...cleanTags].sort((a, b) => a.localeCompare(b));
    },
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
  },
  methods: {
    gotoFilteredProducts(tag) {
      let producsRoute = this.$i18n.locale + "/" + this.$t("routes.products");
      this.$router.push("/" + producsRoute + "/" + tag);
    },
    getCleanTag(tag) {
      if (tag.startsWith("[") && tag.endsWith("]")) {
        tag = tag.substring(1, tag.length - 1);
      }
      return tag;
    },
  },
};
</script>
<style scoped>
.badge {
  cursor: pointer;
  text-decoration: none;
}
.badge-globalcolor {
  background-color: v-bind(globalColor) !important;
  color: v-bind(globalTextColor) !important;
  text-transform: capitalize;
}
</style>
