<template>
  <div class="col-md-6">
    <div
      class="card mb-4"
      :style="'background-color: ' + color + '; border: none'"
    >
      <div class="row g-0">
        <div class="col-md-6 order-2 order-lg-1">
          <div class="card-body text-light text-container ps-4 pe-4 pb-2">
            <h5 class="card-title" v-html="title"></h5>
            <p class="card-text" v-html="content" v-if="content"></p>
            <p v-if="btn_url_slug && btn_text.length">
              <button
                type="button"
                class="btn btn-light"
                @click="gotoProduct(btn_url_slug)"
              >
                {{ btn_text }}
              </button>
            </p>
          </div>
        </div>
        <div class="col-md-6 order-1 order-lg-2">
          <div class="fill">
            <ImageCarousel :images="images" ratio="3x4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCarousel from "./ImageCarousel.vue";

export default {
  name: "FeaturedComponent",
  props: {
    title: String,
    color: String,
    content: String,
    images: Object,
    type: String,
    btn_text: String,
    btn_url_slug: String,
    open_in_blank: Boolean,
  },
  // created() {
  //   console.log(this.image);
  // },
  components: {
    ImageCarousel,
  },
  methods: {
    getImgUrl() {
      return process.env.VUE_APP_IMAGEURL;
    },
    gotoProduct(slug) {
      if (this.type == "product") {
        let uri = this.$i18n.locale + "/" + this.$t("routes.product");
        this.$router.push("/" + uri + "/" + slug);
      } else {
        if (this.open_in_blank) {
          window.open(this.btn_url_slug, "_blank");
        } else {
          window.location.href = this.btn_url_slug;
        }
      }
    },
  },
};
</script>

<style scoped>
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Push content to the bottom */
  height: 100%; /* Ensure container has a height */
}
.fill {
  width: 100%;
  height: 100%;
  aspect-ratio: 3/4;
}

.fill img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
