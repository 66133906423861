import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import i18n from "@/i18n";
import HomeView from "../views/HomeView.vue";
import ProductsView from "@/views/ProductsView.vue";
import ProductView from "@/views/ProductView.vue";
import LanguageSwitcher from "@/views/LanguageSwitcher.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import PageView from "@/views/PageView.vue";
import ShopsView from "@/views/ShopsView.vue";

const routes = [
  // Root path for language switcher
  {
    path: "/",
    name: "langswitcher",
    component: LanguageSwitcher,
  },

  // English Products
  {
    path: "/:locale(en)/products",
    name: "products-en",
    component: ProductsView,
    props: (route) => ({
      locale: route.params.locale,
      tag: null,
      pageNumber: null,
    }),
  },
  // Dutch Products
  {
    path: "/:locale(nl)/producten",
    name: "producten-nl",
    component: ProductsView,
    props: (route) => ({
      locale: route.params.locale,
      tag: null,
      pageNumber: null,
    }),
  },
  // Pagination for products (English)
  {
    path: "/:locale(en)/products/page/:pageNumber(\\d+)",
    name: "products-pagination-en",
    component: ProductsView,
    props: (route) => ({
      locale: route.params.locale,
      tag: null,
      pageNumber: Number(route.params.pageNumber),
    }),
  },
  // Pagination for products (Dutch)
  {
    path: "/:locale(nl)/producten/pagina/:pageNumber(\\d+)",
    name: "producten-pagination-nl",
    component: ProductsView,
    props: (route) => ({
      locale: route.params.locale,
      tag: null,
      pageNumber: Number(route.params.pageNumber),
    }),
  },
  // English category-specific products
  {
    path: "/:locale(en)/products/:tag",
    name: "products-category-en",
    component: ProductsView,
    props: (route) => ({
      locale: route.params.locale,
      tag: route.params.tag,
      pageNumber: null,
    }),
  },
  // Dutch category-specific products
  {
    path: "/:locale(nl)/producten/:tag",
    name: "producten-category-nl",
    component: ProductsView,
    props: (route) => ({
      locale: route.params.locale,
      tag: route.params.tag,
      pageNumber: null,
    }),
  },
  // Category-specific with pagination (English)
  {
    path: "/:locale(en)/products/:tag/page/:pageNumber(\\d+)",
    name: "category-pagination-en",
    component: ProductsView,
    props: (route) => ({
      locale: route.params.locale,
      tag: route.params.tag,
      pageNumber: Number(route.params.pageNumber),
    }),
  },
  // Category-specific with pagination (Dutch)
  {
    path: "/:locale(nl)/producten/:tag/pagina/:pageNumber(\\d+)",
    name: "category-pagination-nl",
    component: ProductsView,
    props: (route) => ({
      locale: route.params.locale,
      tag: route.params.tag,
      pageNumber: Number(route.params.pageNumber),
    }),
  },

  // English Product
  {
    path: "/:locale(en)/product/:slug",
    name: "product-en",
    component: ProductView,
  },
  // Dutch Product
  {
    path: "/:locale(nl)/product/:slug",
    name: "product-nl",
    component: ProductView,
  },

  // English Shop
  {
    path: "/:locale(en)/shops",
    name: "shop-en",
    component: ShopsView,
  },
  // Dutch Winkel
  {
    path: "/:locale(nl)/winkels",
    name: "winkel-nl",
    component: ShopsView,
  },

  // English Page
  {
    path: "/:locale(en)/:slug",
    name: "page-en",
    component: PageView,
  },
  // Dutch Page
  {
    path: "/:locale(nl)/:slug",
    name: "page-nl",
    component: PageView,
  },

  // Catch-all for 404
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFoundView,
  },

  // Home route for localized entry
  {
    path: "/:locale(en|nl)?",
    name: "home",
    component: HomeView,
  },

  // Catch-all route for 404
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to switch routes when locale changes
router.beforeEach((to, from, next) => {
  const { locale } = to.params;
  if (locale && store.getters.getLang !== locale) {
    // console.log("langchange:", locale);
    // Set the default language to switch to if
    // the user visits the "/" and the languageSwitcher
    // needs to change the locale
    store.commit("setLang", locale);
    // Set the i18n locale
    i18n.global.locale = locale;
    next(); // Continue with the route
  } else {
    next();
  }
});

export default router;
