<!-- Featured Hero Component -->
<template>
  <div
    class="container pull-up bg-globalcolor p-0"
    v-if="featuredPost && !loading"
  >
    <div class="row">
      <div
        class="col-md-6 text-light pt-5 pe-5 ps-5 pb-3 mt-5 order-2 order-lg-1"
      >
        <div class="text-container">
          <h1>{{ featuredPost.title }}</h1>
          <div
            class="lead"
            v-html="featuredPost.content"
            v-if="featuredPost.content"
          ></div>
          <p>
            <button
              type="button"
              class="btn btn-light"
              @click="
                gotoProduct(featuredPost.btn_url, featuredPost.open_in_blank)
              "
              v-if="featuredPost.btn_url && featuredPost.btn_text"
            >
              {{ featuredPost.btn_text }}
            </button>
          </p>
        </div>
      </div>
      <div class="col-md-6 order-1 order-lg-2">
        <div class="ratio ratio-1x1">
          <!-- <img
            class="img-fluid"
            src="../assets/695d1096eb8a5dfb5c589ec297841444-600800.jpg"
            width="100%"
          /> -->
          <!-- <img
            class="img-fluid"
            :src="getImgUrl() + featuredPost.image.path"
            width="100%"
          /> -->
          <ImageCarousel :images="featuredPost.images" ratio="1x1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCarousel from "./ImageCarousel.vue";

export default {
  name: "FeaturedHero",
  components: {
    ImageCarousel,
  },
  computed: {
    featuredPost() {
      let featuredPosts = this.$store.getters.getFeaturedPosts;
      if (featuredPosts[0]) {
        this.$store.commit("setGlobalColor", featuredPosts[0].color);
      }
      return featuredPosts[0];
    },
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  created() {
    this.$store.commit("setGlobalColor", "#000000");
  },
  methods: {
    getImgUrl() {
      return process.env.VUE_APP_IMAGEURL;
    },
    gotoProduct(url, blank) {
      if (blank) {
        console.log(blank);
        window.open(url, "_blank");
      } else {
        window.location.href = url;
      }
    },
  },
};
</script>

<style scoped>
.ratio img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Push content to the bottom */
  height: 100%; /* Ensure container has a height */
  color: v-bind(globalTextColor) !important;
}
.lead a {
  color: v-bind(globalTextColor) !important;
}
.bg-globalcolor {
  background-color: v-bind(globalColor);
}
</style>
