<template>
  <nav class="navbar navbar-expand fixed-top mt-2" v-if="!loading">
    <div
      class="container pe-0 ps-0 menu"
      :style="
        'background-color: rgba(' +
        hexToRgb('#ffffff') +
        ',' +
        map(scrollY, 0, 100, 0.0, 0.9) +
        ') !important;'
      "
    >
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-lg-0">
          <ProductFilter />
          <li class="nav-item capitalize">
            <a
              :href="$t('routes.home') + '/' + $t('routes.shops')"
              class="btn btn-globalcolor contrast-text me-2"
              :class="checkIfRoute('shops') ? 'active-globalcolor' : ''"
              type="button"
            >
              {{ $t("message.shops") }}
            </a>
          </li>
          <SearchComponent class="me-2" />
          <LangSelector />
        </ul>
      </div>

      <a class="navbar-brand ms-auto logo me-0" :href="$t('routes.home')">
        <img src="../assets/logo.png" height="50" />
      </a>
    </div>
  </nav>
</template>

<script>
import LangSelector from "./LangSelector.vue";
import SearchComponent from "./SearchComponent.vue";
import ProductFilter from "./ProductFilter.vue";

export default {
  data() {
    return {
      scrollY: 0, // Store the vertical scroll position
      collapsed: false,
      langs: ["nl", "en"],
    };
  },
  components: {
    SearchComponent,
    LangSelector,
    ProductFilter,
  },
  created() {
    this.$store.dispatch("fetchProductFilters");
    this.$store.dispatch("fetchProductPrevNext");
    // console.log(this.$i18n.locale);
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    getLang() {
      return this.$store.getters.getLang;
    },
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY; // Update the scroll position
    },
    map(value, inMin, inMax, outMin, outMax) {
      const mapped =
        ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
      // Clamp the output
      return Math.min(Math.max(mapped, outMin), outMax);
    },
    hexToRgb(hex) {
      // Remove the hash (#) if it exists
      hex = hex.replace(/^#/, "");

      // Handle shorthand HEX codes (e.g., #FFF)
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((char) => char + char)
          .join("");
      }

      // Parse the HEX values to integers
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `${r}, ${g}, ${b}`;
    },
    getContrastYIQ(hexColor) {
      // Remove the hash if present
      hexColor = hexColor.replace("#", "");

      // Parse the hex color to RGB
      const r = parseInt(hexColor.substr(0, 2), 16);
      const g = parseInt(hexColor.substr(2, 2), 16);
      const b = parseInt(hexColor.substr(4, 2), 16);

      // Calculate YIQ
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;

      // Return white or black depending on YIQ value
      return yiq >= 128 ? "black" : "white";
    },
    checkIfRoute(linkname) {
      if (linkname == "products") {
        let routename = this.$route.name;
        // console.log(routename);
        if (
          routename == "producten-nl" ||
          routename == "products-en" ||
          routename == "products-pagination-en" ||
          routename == "products-pagination-nl" ||
          routename == "producten-category-en" ||
          routename == "producten-category-nl" ||
          routename == "category-pagination-en" ||
          routename == "category-pagination-nl"
        ) {
          return true;
        }
      } else if (linkname == "shops") {
        let routename = this.$route.name;
        // console.log(routename);
        if (routename == "shop-en" || routename == "winkel-nl") {
          return true;
        }
      }
    },
    sortedFilters(filters) {
      return filters.slice().sort((a, b) => a._o - b._o);
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("fetchProductFilters");
    },
  },
  mounted() {
    // Add the scroll event listener when the component is mounted
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // Clean up the scroll event listener to avoid memory leaks
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
/* General Navbar */
.navbar {
  font-weight: 300;
}

/* Logo */
.logo {
  background-color: v-bind(globalColor);
}
.logo:hover {
  opacity: 0.7;
}

/* Capitalise first letter */
.capitalize a::first-letter {
  text-transform: capitalize;
}

/* Navbar Buttons */
.btn-globalcolor,
.btn-globalcolor:active,
.btn-globalcolor:visited {
  background-color: v-bind(globalColor) !important;
  color: v-bind(globalTextColor) !important;
}
.btn-globalcolor:hover {
  opacity: 0.5;
}
.active-globalcolor {
  opacity: 0.7;
}
</style>
