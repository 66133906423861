import router from "@/router";
import i18n from "@/i18n";

export function generateLocalizedRoute(targetLocale) {
  const currentRoute = router.currentRoute.value;
  const { params, path } = currentRoute;

  //   console.log("Current route:", path);
  //   console.log("Current params:", params);
  //   console.log("Target locale:", targetLocale);

  // Save the current locale
  const originalLocale = i18n.global.locale;

  // Temporarily switch to the target locale for translation
  i18n.global.locale = targetLocale;

  // Split the path into segments
  const segments = path.split("/").filter(Boolean);
  //   console.log("Route segments:", segments);

  // Translate static segments and preserve dynamic segments
  const translatedSegments = segments.map((segment, index) => {
    // console.log(`Processing segment: "${segment}"`);

    if (index === 0 && params.locale) {
      // Replace the locale segment with the target locale
      //   console.log(`Replacing locale "${segment}" with "${targetLocale}"`);
      return targetLocale;
    }

    // Attempt to translate static segments using i18n
    const currentLocaleMessages =
      i18n.global.messages[originalLocale]?.routes || {};
    const targetLocaleMessages =
      i18n.global.messages[targetLocale]?.routes || {};

    // Check if the current segment is a static route key
    const matchedKey = Object.keys(currentLocaleMessages).find(
      (key) => currentLocaleMessages[key] === segment
    );

    if (matchedKey) {
      const translatedValue = targetLocaleMessages[matchedKey];
      //   console.log(
      //     `Translating static segment "${segment}" using key "${matchedKey}" to "${translatedValue}"`
      //   );
      return translatedValue;
    }

    // If no translation is found, treat it as a dynamic segment
    // console.log(`Dynamic segment detected: "${segment}"`);
    return segment;
  });

  // Restore the original locale
  i18n.global.locale = originalLocale;

  // Construct the new route
  const newRoute = `/${translatedSegments.join("/")}`;
  //   console.log("Translated route:", newRoute);

  return newRoute;
}
