<template>
  <NavBar class="nav-desk d-none d-md-block" />
  <NavBarMobile class="nav-mob d-md-none" />
  <NavBarMobileModal />
  <router-view :key="$route.fullPath" />
  <FooterSection />
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import NavBarMobile from "@/components/NavBarMobile.vue";
import NavBarMobileModal from "@/components/NavBarMobileModal.vue";
import FooterSection from "./components/FooterSection.vue";

export default {
  components: {
    NavBar,
    NavBarMobile,
    NavBarMobileModal,
    FooterSection,
  },
};
</script>

<style lang="scss">
html,
body {
  overflow-x: hidden;
}

@import "styles/preBootstrap.scss";
@import "bootstrap/scss/bootstrap.scss";
@import "styles/custom.scss";
</style>
