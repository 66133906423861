<template>
  <li>
    <form class="d-flex" role="search" @submit.prevent="gotoFilteredProducts">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          :placeholder="$t('text.search')"
          :aria-label="$t('text.search')"
          aria-describedby="search-btn-field"
          v-model="searchField"
        />
        <button
          class="btn btn-globalcolor"
          id="search-btn-field"
          :disabled="isDisabled"
          type="submit"
        >
          <i class="bi bi-search"></i>
        </button>
      </div>
    </form>
  </li>
</template>

<script>
export default {
  name: "SearchComponent",
  data() {
    return {
      searchField: "",
    };
  },
  computed: {
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
    isDisabled() {
      if (this.searchField.length < 2) {
        return true;
      }
      return false;
    },
  },
  methods: {
    gotoFilteredProducts() {
      let producsRoute = this.$i18n.locale + "/" + this.$t("routes.products");
      this.$router.push("/" + producsRoute + "/" + this.searchField);
    },
  },
};
</script>

<style scoped>
.btn-globalcolor,
.btn-globalcolor:active,
.btn-globalcolor:visited {
  background-color: v-bind(globalColor) !important;
  color: v-bind(globalTextColor) !important;
  border-color: white;
}
.btn-globalcolor:hover {
  opacity: 0.5;
}
</style>
