<template>
  <MetaManager
    v-if="!loading && product"
    :title="product.title"
    :description="product.content[0]"
    :keywords="product.tags"
    :image="product.images[0].path"
  />

  <ProductHero :product="product" v-if="!loading && product" />
  <!-- <PrevNextComponent :productId="product._id" v-if="!loading && product" /> -->
  <div class="container p-0" v-if="!loading && product">
    <div class="row">
      <div class="col-md-6 pt-3 ps-5 pe-5">
        <div v-if="product.content && product.content.length > 2" class="lead">
          <div
            v-for="(item, index) in product.content.slice(
              1,
              product.content.length - 1
            )"
            :key="index"
            v-html="item"
          ></div>
        </div>

        <ArticlesComponent
          v-if="product.articles"
          :articles="product.articles"
        />
      </div>
      <div class="col-md-6 pt-3 ps-5 pe-5">
        <div
          v-if="product.content && product.content.length"
          class="lead"
          v-html="product.content[product.content.length - 1]"
        ></div>
        <div class="codes pb-2" v-if="product.sku && product.ean13">
          {{ product.sku }} - {{ product.ean13 }}
        </div>
        <TagComponent :tags="product.tags" />
      </div>
    </div>
  </div>
</template>

<script>
import ArticlesComponent from "@/components/ArticlesComponent.vue";
import ProductHero from "@/components/ProductHero.vue";
import TagComponent from "@/components/TagComponent.vue";
import MetaManager from "@/components/MetaManager.vue";
// import PrevNextComponent from "@/components/PrevNextComponent.vue";

export default {
  name: "ProductView",
  components: {
    ProductHero,
    TagComponent,
    MetaManager,
    ArticlesComponent,
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    product() {
      let product = this.$store.getters.getProduct;
      if (product) {
        this.$store.commit("setGlobalColor", product.color);
      }
      return product;
    },
  },
  created() {
    let slug = this.$route.params.slug;
    this.$store.dispatch("fetchProduct", slug);
  },
};
</script>

<style scoped>
.codes {
  font-family: monospace, monospace;
  font-weight: 100;
}
</style>
