<template>
  <section class="pt-4 pb-5 mt-5 bg-light border-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 order-1 order-md-1 order-lg-1">
          <p
            v-if="footer.footer_column_1_txt"
            v-html="footer.footer_column_1_txt"
          ></p>
          <p
            class="footer-links"
            v-for="page in footer.footer_column_1"
            :key="page._id"
          >
            <a @click="gotoPage(page.slug)">{{ page.title }}</a>
          </p>
        </div>
        <div class="col-lg-3 order-2 order-md-3 order-lg-2">
          <p
            v-if="footer.footer_column_2_txt"
            v-html="footer.footer_column_2_txt"
          ></p>
          <p v-for="page in footer.footer_column_2" :key="page._id">
            <a @click="gotoPage(page.slug)">{{ page.title }}</a>
          </p>
        </div>
        <div class="col-lg-3 order-3 order-md-4 order-lg-3">
          <p
            v-if="footer.footer_column_3_txt"
            v-html="footer.footer_column_3_txt"
          ></p>
          <p v-for="page in footer.footer_column_3" :key="page._id">
            <a @click="gotoPage(page.slug)">{{ page.title }}</a>
          </p>
        </div>
        <div class="col-lg-3 order-4 order-md-2 order-lg-4">
          <p
            v-if="footer.footer_column_4_txt"
            v-html="footer.footer_column_4_txt"
          ></p>
          <p v-for="page in footer.footer_column_4" :key="page._id">
            <a @click="gotoPage(page.slug)">{{ page.title }}</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterSection",
  created() {
    this.$store.dispatch("fetchFooter");
  },
  computed: {
    footer() {
      return this.$store.getters.getFooter;
    },
  },
  methods: {
    gotoPage(slug) {
      let uri = this.$i18n.locale;
      this.$router.push("/" + uri + "/" + slug);
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("fetchFooter");
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
p {
  font-weight: 300;
}
.footer-links {
  display: block;
  margin-bottom: 0.5rem !important;
  margin-block-start: 4px !important;
  margin-block-end: 4px !important;
}
</style>
