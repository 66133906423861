<!-- Featured Hero Component -->
<template>
  <div class="container pull-up bg-globalcolor p-0" v-if="product && !loading">
    <div class="row">
      <div class="col-md-6 text-light pt-5 ps-5 pb-3 mt-5 order-2 order-lg-1">
        <div class="text-container">
          <h1>{{ product.title }}</h1>
          <div
            v-if="product.content && product.content.length"
            class="lead"
            v-html="product.content[0]"
          ></div>
        </div>
      </div>
      <div class="col-md-6 order-1 order-lg-2 position-relative">
        <!-- Image carousel stays inside ratio div -->
        <div class="ratio ratio-1x1">
          <ImageCarousel :images="product.images" />
        </div>
        <StickerComponent :tags="product.tags" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageCarousel from "./ImageCarousel.vue";
import StickerComponent from "./StickerComponent.vue";

export default {
  name: "ProductHero",
  components: {
    ImageCarousel,
    StickerComponent,
  },
  props: { product: Object },
  computed: {
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  created() {
    this.$store.commit("setGlobalColor", "#000000");
  },
  methods: {
    getImgUrl() {
      return process.env.VUE_APP_IMAGEURL;
    },
  },
};
</script>

<style scoped>
.ratio img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Push content to the bottom */
  height: 100%; /* Ensure container has a height */
  color: v-bind(globalTextColor) !important;
}
.lead a {
  color: v-bind(globalTextColor) !important;
}
.bg-globalcolor {
  background-color: v-bind(globalColor);
}
</style>
