<template>
  <FeaturedComponent
    v-for="product in featuredProducts"
    :color="product.color"
    :title="product.title"
    :content="product.content && product.content[0] ? product.content[0] : ''"
    :images="product.images"
    :type="'product'"
    :btn_text="$t('message.view_product')"
    :btn_url_slug="product.slug"
    :open_in_blank="false"
    v-bind:key="product"
  />
</template>

<script>
import FeaturedComponent from "./FeaturedComponent.vue";

export default {
  name: "FeaturedProducts",
  components: {
    FeaturedComponent,
  },
  computed: {
    featuredProducts() {
      return this.$store.getters.getFeaturedProducts;
    },
  },
};
</script>

<style scoped>
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Push content to the bottom */
  height: 100%; /* Ensure container has a height */
}
</style>
