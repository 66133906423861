import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
// import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    lang: "nl",
    footer: {},
    globalColor: "#111111",
    globalTxtColor: "#ffffff",
    pageContent: null,
    featuredProducts: [],
    featuredPosts: [],
    products: [],
    product: null,
    productFilters: [],
    productPrevNext: [],
    totalItems: 0,
    limit: 12,
    page: 1,
    skip: 0,
    filter: {},
    shops: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getLang(state) {
      return state.lang;
    },
    getFooter(state) {
      return state.footer;
    },
    getGlobalColor(state) {
      return state.globalColor;
    },
    getGlobalTextColor(state) {
      return state.globalTxtColor;
    },
    getPageContent(state) {
      return state.pageContent;
    },
    getFeaturedProducts(state) {
      return state.featuredProducts;
    },
    getFeaturedPosts(state) {
      return state.featuredPosts;
    },
    getProducts(state) {
      return state.products;
    },
    getProduct(state) {
      return state.product;
    },
    getProductFilters(state) {
      return state.productFilters;
    },
    getProductPrevNext(state) {
      return state.productPrevNext;
    },
    getFilter(state) {
      return state.filter;
    },
    getTotalItems(state) {
      return state.totalItems;
    },
    getLimit(state) {
      return state.limit;
    },
    getPage(state) {
      return state.page;
    },
    getSkip(state) {
      return state.skip;
    },
    getShops(state) {
      return state.shops;
    },
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setLang(state, value) {
      state.lang = value;
    },
    setFooter(state, value) {
      state.footer = value;
    },
    setGlobalColor(state, value) {
      // change the global color and calulate the contrasting text color
      const hex = value.replace("#", "");
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      let textColor = yiq >= 128 ? "#000000" : "#ffffff";
      state.globalTxtColor = textColor;
      state.globalColor = value;
    },
    setPageContent(state, value) {
      state.pageContent = value;
    },
    setFeaturedProducts(state, value) {
      state.featuredProducts = value;
    },
    setFeaturedPosts(state, value) {
      state.featuredPosts = value;
    },
    setProducts(state, value) {
      state.products = value;
    },
    setProduct(state, value) {
      state.product = value;
    },
    setProductFilters(state, value) {
      state.productFilters = value;
    },
    setProductPrevNext(state, value) {
      state.productPrevNext = value;
    },
    setFilter(state, value) {
      state.filter = value;
    },
    setTotalItems(state, value) {
      state.totalItems = value;
    },
    setLimit(state, value) {
      state.limit = value;
    },
    setPage(state, pageNumber) {
      state.page = pageNumber;
      if (pageNumber >= 1 || pageNumber <= state.totalPages) {
        state.skip = (pageNumber - 1) * state.limit;
      }
    },
    setSkip(state, value) {
      state.skip = value;
    },
    setShops(state, value) {
      state.shops = value;
    },
  },
  actions: {
    fetchFeaturedPosts(state) {
      state.commit("setLoading", true);
      const cockpiturl = process.env.VUE_APP_COCKPITURL;
      const token = process.env.VUE_APP_WEBAPPTOKEN;
      // console.log(state);
      const locale = state.getters.getLang; // Access current locale from i18n
      axios
        .get(
          cockpiturl +
            "/content/items/featuredposts?populate=1&locale=" +
            locale,
          {
            headers: {
              "api-key": token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          state.commit("setFeaturedPosts", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    fetchFeaturedProducts(state) {
      state.commit("setLoading", true);
      const cockpiturl = process.env.VUE_APP_COCKPITURL;
      const token = process.env.VUE_APP_WEBAPPTOKEN;
      // console.log(state);
      const locale = state.getters.getLang; // Access current locale from i18n
      axios
        .get(
          cockpiturl +
            "/content/item/featuredproducts?populate=1&locale=" +
            locale,
          {
            headers: {
              "api-key": token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          state.commit("setFeaturedProducts", response.data.products);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    fetchProductFilters(state) {
      state.commit("setLoading", true);
      const cockpiturl = process.env.VUE_APP_COCKPITURL;
      const token = process.env.VUE_APP_WEBAPPTOKEN;
      const locale = state.getters.getLang; // Access current locale from i18n
      axios
        .get(cockpiturl + "/content/items/productfilters", {
          headers: {
            "api-key": token,
          },
          params: {
            locale: locale,
          },
        })
        .then((response) => {
          // console.log("productfilters", response.data);
          state.commit("setProductFilters", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    fetchFooter(state) {
      state.commit("setLoading", true);
      const cockpiturl = process.env.VUE_APP_COCKPITURL;
      const token = process.env.VUE_APP_WEBAPPTOKEN;
      const locale = state.getters.getLang; // Access current locale from i18n
      axios
        .get(cockpiturl + "/content/item/footer", {
          headers: {
            "api-key": token,
          },
          params: {
            locale: locale,
            populate: 1,
          },
        })
        .then((response) => {
          state.commit("setFooter", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    fetchProducts(state) {
      state.commit("setLoading", true);
      const cockpiturl = process.env.VUE_APP_COCKPITURL;
      const token = process.env.VUE_APP_WEBAPPTOKEN;
      const locale = state.getters.getLang; // Access current locale from i18n
      axios
        .get(cockpiturl + "/content/items/products", {
          headers: {
            "api-key": token,
          },
          params: {
            locale: locale,
            limit: state.getters.getLimit,
            skip: state.getters.getSkip,
            filter: state.getters.getFilter,
            sort: '{"_created":-1 }',
          },
        })
        .then((response) => {
          // console.log(response.data.data);
          state.commit("setProducts", response.data.data);
          state.commit("setTotalItems", response.data.meta.total);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    fetchProduct(state, slug) {
      state.commit("setLoading", true);
      const cockpiturl = process.env.VUE_APP_COCKPITURL;
      const token = process.env.VUE_APP_WEBAPPTOKEN;
      const locale = state.getters.getLang; // Access current locale from i18n
      axios
        .get(cockpiturl + "/content/item/products", {
          headers: {
            "api-key": token,
          },
          params: {
            locale: locale,
            filter: {
              $or: [
                { slug: { $regex: `^${slug}$`, $options: "i" } },
                { slug_en: { $regex: `^${slug}$`, $options: "i" } },
              ],
            },
            populate: 1,
          },
        })
        .then((response) => {
          // console.log(response.data);
          state.commit("setProduct", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    fetchPageContent(state, slug) {
      state.commit("setLoading", true);
      const cockpiturl = process.env.VUE_APP_COCKPITURL;
      const token = process.env.VUE_APP_WEBAPPTOKEN;
      const locale = state.getters.getLang; // Access current locale from i18n
      axios
        .get(cockpiturl + "/content/item/pages", {
          headers: {
            "api-key": token,
          },
          params: {
            locale: locale,
            filter: {
              $or: [
                { slug: { $regex: `^${slug}$`, $options: "i" } },
                { slug_en: { $regex: `^${slug}$`, $options: "i" } },
              ],
            },
            populate: 1,
          },
        })
        .then((response) => {
          // console.log(response.data);
          state.commit("setPageContent", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    fetchShops(state) {
      state.commit("setLoading", true);
      const cockpiturl = process.env.VUE_APP_COCKPITURL;
      const token = process.env.VUE_APP_WEBAPPTOKEN;
      // console.log(state);
      const locale = state.getters.getLang; // Access current locale from i18n
      axios
        .get(cockpiturl + "/content/items/shops?populate=1&locale=" + locale, {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          state.commit("setShops", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    fetchProductPrevNext(state) {
      state.commit("setLoading", true);
      const cockpiturl = process.env.VUE_APP_COCKPITURL;
      const token = process.env.VUE_APP_WEBAPPTOKEN;
      const locale = state.getters.getLang; // Access current locale from i18n
      axios
        .get(cockpiturl + "/content/items/products", {
          headers: {
            "api-key": token,
          },
          params: {
            locale: locale,
            fields: {
              title: 1,
              title_en: 1,
              title_de: 1,
              title_fr: 1,
              slug: 1,
              slug_en: 1,
              slug_de: 1,
              slug_fr: 1,
              _id: 1,
            },
            sort: '{"_created":-1 }',
          },
        })
        .then((response) => {
          state.commit("setProductPrevNext", response.data);
          state.commit("setLoading", false);
        })
        .catch((err) => console.log(err));
    },
    resetPagination(state) {
      state.commit("setLimit", 12);
      state.commit("setPage", 1);
      state.commit("setSkip", 0);
    },
  },
  modules: {},
});
