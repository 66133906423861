<template>
  <FeaturedHero />
  <section class="pt-4">
    <div class="container p-0">
      <div class="row">
        <FeaturedPosts />
        <FeaturedProducts />
      </div>
    </div>
  </section>
</template>

<script>
import FeaturedHero from "@/components/FeaturedHero.vue";
import FeaturedPosts from "@/components/FeaturedPosts.vue";
import FeaturedProducts from "@/components/FeaturedProducts.vue";

export default {
  name: "HomeView",
  components: {
    FeaturedHero,
    FeaturedPosts,
    FeaturedProducts,
  },
  created() {
    this.$store.dispatch("fetchFeaturedPosts");
    this.$store.dispatch("fetchFeaturedProducts");
  },
};
</script>
