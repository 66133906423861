<template>
  <!-- Badge placed outside ratio div but inside col-md-6 -->
  <div class="badge-container">
    <span
      class="badge p-2 ps-2 pe-2 me-2"
      v-for="tag in getStickertags"
      v-bind:key="tag"
      >{{ tag }}</span
    >
  </div>
</template>

<script>
export default {
  name: "StickerComponent",
  props: {
    tags: Object,
  },
  computed: {
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
    getStickertags() {
      return this.tags
        .filter((tag) => tag.startsWith("[") && tag.endsWith("]"))
        .map((tag) => this.getCleanTag(tag));
    },
  },
  created() {},
  methods: {
    getCleanTag(tag) {
      if (tag.startsWith("[") && tag.endsWith("]")) {
        tag = tag.substring(1, tag.length - 1);
      }
      return tag;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-container {
  position: absolute;
  bottom: 30px;
  left: 48px;
  font-size: 1.3rem;
  z-index: 10; /* Ensure it's above the image */
}
.badge {
  color: v-bind(globalTextColor) !important;
  background: v-bind(globalColor);
}
</style>
