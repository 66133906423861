<template>
  <div class="top-spacer"></div>
  <ProductsListComponent />
</template>

<script>
// import FeatureComponent from "@/components/FeatureComponent.vue";
import ProductsListComponent from "@/components/ProductsListComponent.vue";

// @ is an alias to /src

export default {
  name: "ProductsView",
  props: {
    locale: String,
    tag: String,
    pageNumber: Number,
  },
  components: {
    // FeatureComponent,
    ProductsListComponent,
  },
  computed: {
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
  },
  created() {
    this.$store.commit("setGlobalColor", "#000000");
    if (!this.pageNumber) {
      this.$store.commit("setPage", 1);
    } else {
      this.$store.commit("setPage", this.pageNumber);
    }
    // console.log(this.pageNumber);

    // console.log(this.tag);

    if (this.tag && this.tag.startsWith("_")) {
      this.setTagFilter();
    } else if (this.tag) {
      this.setFilter();
    } else {
      // clear the filter
      this.$store.commit("setFilter", {});
    }

    this.$store.dispatch("fetchProducts");
  },
  methods: {
    setTagFilter() {
      let filter = {
        $or: [
          { tags: { $regex: this.tag.substring(1), $options: "i" } },
          { tags_en: { $regex: this.tag.substring(1), $options: "i" } },
        ],
      };
      this.$store.commit("setFilter", filter);
    },
    setFilter() {
      let filter = {
        $or: [
          { tags: { $regex: this.tag, $options: "i" } },
          { tags_en: { $regex: this.tag, $options: "i" } },
          { slug: { $regex: this.tag, $options: "i" } },
          { slug_en: { $regex: this.tag, $options: "i" } },
          { title: { $regex: this.tag, $options: "i" } },
          { title_en: { $regex: this.tag, $options: "i" } },
          { content: { $regex: this.tag, $options: "i" } },
          { content_en: { $regex: this.tag, $options: "i" } },
          { description: { $regex: this.tag, $options: "i" } },
          { description_en: { $regex: this.tag, $options: "i" } },
          { search_terms: { $regex: this.tag, $options: "i" } },
        ],
      };
      this.$store.commit("setFilter", filter);
    },
  },
};
</script>

<style>
.top-spacer {
  height: 60px;
}
</style>
