<template>
  <div class="d-grid capitalize">
    <a
      :href="$t('routes.home') + '/' + $t('routes.products')"
      class="btn btn-globalcolor contrast-text capitalize"
      :class="checkIfRoute('products') ? 'active-globalcolor' : ''"
      type="button"
    >
      {{ $t("message.products") }}
    </a>
  </div>

  <ul class="list-group">
    <!-- Loop through parent filters -->

    <li class="list-group-item">
      <a
        class="dropdown-item capitalize"
        :href="$t('routes.home') + '/' + $t('routes.products')"
      >
        {{ $t("message.all_products") }}
      </a>
    </li>

    <template v-for="filter in sortedProductFilters" :key="filter._id">
      <li class="list-group-item">
        <!-- Parent filter link -->
        <a
          class="d-block"
          :href="`${$t('routes.home')}/${$t(
            'routes.products'
          )}/_${filter.filter_name.toLowerCase()}`"
        >
          {{ filter.filter_name }}
        </a>
      </li>

      <template
        v-for="subFilter in getChildFilters(filter._id)"
        :key="subFilter._id"
      >
        <li class="list-group-item">
          <a
            class="ms-4 d-block"
            :href="`${$t('routes.home')}/${$t(
              'routes.products'
            )}/_${subFilter.filter_name.toLowerCase()}`"
          >
            {{ subFilter.filter_name }}
          </a>
        </li>
      </template>
    </template>
  </ul>
</template>

<script>
export default {
  name: "ProductFilter",
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    getLang() {
      return this.$store.getters.getLang;
    },
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
    getProductFilters() {
      return this.$store.getters.getProductFilters;
    },
    sortedProductFilters() {
      return this.sortProductFilters();
    },
  },
  methods: {
    sortProductFilters() {
      const filters = this.getProductFilters;
      if (!filters) return [];
      return Array.isArray(filters)
        ? filters.filter((filter) => !filter._pid).sort((a, b) => a._o - b._o) // Only sort parent filters
        : [];
    },

    getChildFilters(parentId) {
      const filters = this.getProductFilters;
      if (!Array.isArray(filters)) return [];
      return filters
        .filter((f) => f._pid === parentId)
        .sort((a, b) => a._o - b._o); // Sort child filters
    },

    checkIfRoute(route) {
      return this.$route.path.includes(route);
    },
  },
};
</script>

<style lang="css" scoped>
/* Capitalise first letter */
.capitalize a::first-letter {
  text-transform: capitalize;
}

/* Navbar Buttons */
.btn-globalcolor,
.btn-globalcolor:active,
.btn-globalcolor:visited {
  background-color: v-bind(globalColor) !important;
  color: v-bind(globalTextColor) !important;
}
.btn-globalcolor:hover {
  opacity: 0.5;
}
.active-globalcolor {
  opacity: 0.7;
}

/* Style child filters (indentation) */
.ms-3 {
  margin-left: 1rem; /* Indent child filters */
}
</style>
