<template>
  <div class="col-md-6 col-lg-4">
    <div
      class="card text-bg-dark mb-4"
      style="border: none; cursor: pointer"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="gotoProduct(product.slug)"
      v-if="!loading"
    >
      <div class="fill">
        <img
          :src="getImgUrl() + product.images[0].path"
          class="card-img"
          :alt="product.title"
        />
      </div>
      <div
        class="card-img-overlay text-container p-0"
        :style="'background-color: rgba(' + hexToRgb(product.color) + ', 0)'"
        v-if="hover"
      >
        <h5
          class="p-4 mb-0"
          :style="'border:none;background-color: ' + product.color"
          v-html="product.title"
        ></h5>
        <p
          class="pt-0 ps-4 pe-4 pb-1 mb-0"
          :style="'border:none;background-color: ' + product.color"
          v-if="product.content"
          v-html="product.content[0]"
        ></p>
      </div>

      <div
        class="card-img-overlay text-container p-0"
        :style="'background-color: rgba(' + hexToRgb(product.color) + ', 0)'"
        v-if="!hover"
      >
        <h5
          class="p-4 mb-0"
          :style="'border:none;background-color: ' + product.color"
          v-html="product.title"
        ></h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    product: Object,
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    hexToRgb(hex) {
      // Remove the hash (#) if it exists
      hex = hex.replace(/^#/, "");

      // Handle shorthand HEX codes (e.g., #FFF)
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((char) => char + char)
          .join("");
      }

      // Parse the HEX values to integers
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `${r}, ${g}, ${b}`;
    },
    getImgUrl() {
      return process.env.VUE_APP_IMAGEURL;
    },
    gotoProduct(slug) {
      let uri = this.$i18n.locale + "/" + this.$t("routes.product");
      this.$router.push("/" + uri + "/" + slug);
    },
  },
};
</script>

<style scoped>
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.text-container p {
  line-height: 1.5em; /* Adjust based on your font size */
  height: 6em; /* 3 lines * 1.5em */
  overflow: hidden; /* Prevent extra content */
}
.card {
  aspect-ratio: 3/4;
}
.fill {
  width: 100%;
  height: 100%;
}

.fill img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.trigger {
  cursor: pointer;
}

.target {
  display: none;
  /* visibility: hidden; */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.trigger:hover + .target {
  /* visibility: visible; */
  display: block;
  opacity: 1;
}
</style>
