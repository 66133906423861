<template>
  <div></div>
  <MetaManager
    v-if="!loading && pageContent"
    :title="pageContent.title"
    :description="pageContent.content[0]"
    :keywords="''"
    :image="pageContent.images.length > 0 ? pageContent.images[0].path : null"
  />
  <PageHero :pageContent="pageContent" v-if="!loading && pageContent" />

  <div class="container p-0" v-if="!loading && pageContent">
    <div class="row">
      <div class="col-md-6 pt-3 ps-5 pe-5">
        <div
          v-if="pageContent.content && pageContent.content.length > 1"
          class="lead"
        >
          <div
            v-for="(item, index) in pageContent.content.slice(1)"
            :key="index"
            v-html="item"
          ></div>
        </div>
      </div>
      <div class="col-md-6 pt-3 ps-5 pe-5">
        <ArticlesComponent
          v-if="!loading && pageContent.articles"
          :articles="pageContent.articles"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArticlesComponent from "@/components/ArticlesComponent.vue";
import PageHero from "@/components/PageHero.vue";
import MetaManager from "@/components/MetaManager.vue";

export default {
  name: "PageView",
  components: {
    PageHero,
    MetaManager,
    ArticlesComponent,
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    pageContent() {
      let pageContent = this.$store.getters.getPageContent;
      if (pageContent) {
        if (pageContent.color) {
          this.$store.commit("setGlobalColor", pageContent.color);
        }
      }
      return pageContent;
    },
  },
  created() {
    let slug = this.$route.params.slug;
    this.$store.dispatch("fetchPageContent", slug);
  },
};
</script>

<style></style>
