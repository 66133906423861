<template>
  <!-- SHOP NAV-->
  <ul class="nav nav-pills mb-3">
    <li class="nav-item">
      <a
        class="nav-link"
        aria-current="page"
        :class="brickShops ? 'bg-globalcolor text-globalcolor' : ''"
        @click="brickShops = true"
      >
        {{ $t("text.brick_shops") }}
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        aria-current="page"
        :class="brickShops ? '' : 'bg-globalcolor text-globalcolor'"
        @click="brickShops = false"
      >
        {{ $t("text.web_shops") }}
      </a>
    </li>
    <form class="ms-3 d-flex" role="search">
      <!-- SEARCH INPUT-->
      <div class="input-group capitalize">
        <input
          type="search"
          class="form-control capitalize"
          :placeholder="$t('text.search')"
          :aria-label="$t('text.search')"
          v-model="searchQuery"
        />
        <!-- <span class="input-group-text" id="basic-addon1"
          ><i class="bi bi-search"></i
        ></span> -->
      </div>
    </form>
  </ul>

  <!-- SHOPS-->
  <div
    class="row row-cols-1 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-4"
  >
    <!-- FEATURED SHOPS-->
    <div class="col" v-for="shop in featuredShops" :key="shop.id">
      <div class="card h-100 border-globalcolor">
        <div class="card-body">
          <h5 class="card-title">
            {{ shop.name }} <i class="bi bi-bookmark-star"></i>
          </h5>
          <p class="card-text">
            <span v-if="shop.name2">{{ shop.name2 }}</span>
            <span v-if="shop.street">{{ shop.street }}<br /></span>
            <span v-if="shop.postcode">{{ shop.postcode }} &nbsp;</span>
            <span v-if="shop.city">{{ shop.city }}<br /> </span>
            <span v-if="shop.country">{{ shop.country }}<br /></span>
            <a
              class="small mt-1 btn btn-sm btn-outline-primary"
              :href="shop.url"
              target="_blank"
            >
              <span v-if="shop.has_webshop">{{ $t("text.visit_shop") }}</span>
              <span v-else>{{ $t("text.visit_website") }}</span>
            </a>
          </p>
        </div>
      </div>
    </div>
    <!-- SHOPS-->
    <div class="col" v-for="shop in shops" :key="shop.id">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="card-title">{{ shop.name }}</h5>
          <p class="card-text">
            <span v-if="shop.name2">{{ shop.name2 }}</span>
            <span v-if="shop.street">{{ shop.street }}<br /></span>
            <span v-if="shop.postcode">{{ shop.postcode }} &nbsp;</span>
            <span v-if="shop.city">{{ shop.city }}<br /> </span>
            <span v-if="shop.country">{{ shop.country }}<br /></span>
            <a
              class="small mt-1 btn btn-sm btn-outline-primary"
              :href="shop.url"
              target="_blank"
            >
              <span v-if="shop.has_webshop">{{ $t("text.visit_shop") }}</span>
              <span v-else>{{ $t("text.visit_website") }}</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brickShops: true,
      searchQuery: "", // Add search query state
    };
  },
  computed: {
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
    filteredShops() {
      let shops = this.$store.getters.getShops || [];
      if (this.searchQuery.trim() === "") {
        return shops; // No filtering if search query is empty
      }

      const query = this.searchQuery.toLowerCase();
      return shops.filter((shop) =>
        ["name", "name2", "street", "postcode", "city", "country"]
          .map((key) => shop[key]?.toLowerCase() || "")
          .some((value) => value.includes(query))
      );
    },
    featuredShops() {
      return this.filteredShops
        .filter(
          (shop) =>
            shop.has_complete_collection &&
            (this.brickShops ? shop.has_physical_shop : shop.has_webshop)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    shops() {
      return this.filteredShops
        .filter(
          (shop) =>
            !shop.has_complete_collection &&
            (this.brickShops ? shop.has_physical_shop : shop.has_webshop)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>

<style scoped>
.text-globalcolor {
  color: v-bind(globalTextColor) !important;
}
.bg-globalcolor {
  background-color: v-bind(globalColor);
}
.border-globalcolor {
  border-color: v-bind(globalColor);
}
/* Capitalise first letter */
.capitalize input::placeholder {
  text-transform: capitalize;
}
</style>
