<template>
  <div
    class="modal fade"
    id="NavBarMobileModal"
    tabindex="-1"
    aria-labelledby="NavBarMobileModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <nav class="navbar navbar-expand mt-2" v-if="!loading">
          <div class="container pe-0 ps-0 menu">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto">
                <li class="nav-item capitalize">
                  <a
                    class="btn btn-globalcolor contrast-text ms-2 me-3"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    <i class="bi bi-x-lg"></i>
                  </a>
                </li>
                <LangSelector />
              </ul>
            </div>

            <a class="navbar-brand ms-auto logo me-0" :href="$t('routes.home')">
              <img src="../assets/logo.png" height="50" />
            </a>
          </div>
        </nav>

        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <ul class="navbar-nav">
                  <SearchComponent class="mb-2" />
                </ul>
                <div class="mb-2">
                  <ProductFilterMobile />
                </div>
                <div class="d-grid capitalize">
                  <a
                    :href="$t('routes.home') + '/' + $t('routes.shops')"
                    class="btn btn-globalcolor contrast-text capitalize"
                    :class="checkIfRoute('shops') ? 'active-globalcolor' : ''"
                    type="button"
                  >
                    {{ $t("message.shops") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import LangSelector from "./LangSelector.vue";
import SearchComponent from "./SearchComponent.vue";
import ProductFilterMobile from "./ProductFilterMobile.vue";

export default {
  name: "NavBarMobileModal",
  data() {
    return {
      langs: ["nl", "en"],
      modalInstance: null,
    };
  },
  mounted() {
    // Ensure the modal is correctly initialized
    const modalEl = document.getElementById("NavBarMobileModal");
    if (modalEl) {
      this.modalInstance = new Modal(modalEl);
    } else {
      console.error("Modal element not found!");
    }
  },
  watch: {
    $route() {
      // console.log("Route changed from", from.fullPath, "to", to.fullPath);
      this.closeModal();
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    getLang() {
      return this.$store.getters.getLang;
    },
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
  },
  methods: {
    openModal() {
      if (this.modalInstance) {
        this.modalInstance.show();
      }
    },
    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },
    hexToRgb(hex) {
      // Remove the hash (#) if it exists
      hex = hex.replace(/^#/, "");

      // Handle shorthand HEX codes (e.g., #FFF)
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((char) => char + char)
          .join("");
      }

      // Parse the HEX values to integers
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `${r}, ${g}, ${b}`;
    },
    map(value, inMin, inMax, outMin, outMax) {
      const mapped =
        ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
      // Clamp the output
      return Math.min(Math.max(mapped, outMin), outMax);
    },
    checkIfRoute(route) {
      return this.$route.path.includes(route);
    },
  },
  components: {
    LangSelector,
    SearchComponent,
    ProductFilterMobile,
  },
};
</script>

<style lang="scss" scoped>
/* General Navbar */
.navbar {
  font-weight: 300;
}

/* Logo */
.logo {
  background-color: v-bind(globalColor);
}
.logo:hover {
  opacity: 0.7;
}

.bi {
  font-size: 1.3rem;
}

/* Capitalise first letter */
.capitalize a::first-letter {
  text-transform: capitalize;
}

/* Navbar Buttons */
.btn-globalcolor,
.btn-globalcolor:active,
.btn-globalcolor:visited {
  background-color: v-bind(globalColor) !important;
  color: v-bind(globalTextColor) !important;
}
.btn-globalcolor:hover {
  opacity: 0.5;
}
.active-globalcolor {
  opacity: 0.7;
}
</style>
