<template>
  <div v-if="false"><!-- This component does not render anything --></div>
</template>

<script>
export default {
  name: "MetaManager",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: [String, Array],
      default: "",
      validator(value) {
        if (Array.isArray(value)) {
          return value.every((item) => typeof item === "string");
        }
        return typeof value === "string";
      },
    },
    keywords: {
      type: [String, Array],
      default: "",
      validator(value) {
        if (Array.isArray(value)) {
          return value.every((item) => typeof item === "string");
        }
        return typeof value === "string";
      },
    },
    image: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: () => window.location.href, // Default to current URL
    },
    creator: {
      type: String,
      default: "Sephen - sephen.nl", // Default to empty, but can be overridden
    },
  },
  mounted() {
    this.updateMeta();
  },
  watch: {
    title() {
      this.updateMeta();
    },
    description() {
      this.updateMeta();
    },
    keywords() {
      this.updateMeta();
    },
    image() {
      this.updateMeta();
    },
    url() {
      this.updateMeta();
    },
    creator() {
      this.updateMeta();
    },
  },
  methods: {
    updateMeta() {
      // Update page title
      document.title = this.title + " | " + this.getAppName();

      // Convert description to string if it's an array
      let descriptionContent = Array.isArray(this.description)
        ? this.description.join(" ")
        : this.description;

      // Update description meta tag
      this.updateTag("name", "description", descriptionContent);

      // Convert keywords to string if it's an array
      let keywordsContent = Array.isArray(this.keywords)
        ? this.keywords.join(",")
        : this.keywords;

      // Update keywords meta tag
      this.updateTag("name", "keywords", keywordsContent);

      // Update creator meta tag
      this.updateTag("name", "creator", this.creator);

      // Update Open Graph meta tags
      this.updateTag("property", "og:title", this.title);
      this.updateTag("property", "og:description", descriptionContent);
      if (this.image) {
        this.updateTag("property", "og:image", this.getImgUrl() + this.image);
      }
      this.updateTag("property", "og:url", this.url);
    },
    updateTag(attribute, name, content) {
      if (!content) return;

      let tag = document.querySelector(`meta[${attribute}="${name}"]`);
      if (!tag) {
        tag = document.createElement("meta");
        tag.setAttribute(attribute, name);
        document.head.appendChild(tag);
      }
      tag.setAttribute("content", content);
    },
    getAppName() {
      return process.env.VUE_APP_NAME;
    },
    getImgUrl() {
      return process.env.VUE_APP_IMAGEURL;
    },
  },
};
</script>

<style scoped>
/* This component does not have any styling */
</style>
