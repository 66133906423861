<template>
  <FeaturedComponent
    v-for="post in featuredPosts"
    :color="post.color"
    :title="post.title"
    :content="post.content"
    :images="post.images"
    :type="'post'"
    :btn_text="post.btn_text"
    :btn_url_slug="post.btn_url"
    :open_in_blank="post.open_in_blank"
    v-bind:key="post"
  />
</template>

<script>
import FeaturedComponent from "./FeaturedComponent.vue";

export default {
  name: "FeaturedProducts",
  components: {
    FeaturedComponent,
  },
  computed: {
    featuredPosts() {
      return this.$store.getters.getFeaturedPosts.slice(1);
    },
  },
};
</script>

<style scoped>
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Push content to the bottom */
  height: 100%; /* Ensure container has a height */
}
</style>
