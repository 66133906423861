<template>
  <!-- <MetaManager
    v-if="pageContent"
    :title="pageContent.title"
    :description="pageContent.content[0]"
    :keywords="''"
    :image="pageContent.images.length > 0 ? pageContent.images[0].path : null"
  /> -->
  <ShopsHero />

  <div class="container p-0">
    <div class="row">
      <div class="col-md-12 pt-3 ps-5 pe-5">
        <ShopsComponent />
      </div>
    </div>
  </div>
</template>

<script>
// import MetaManager from "@/components/MetaManager.vue";
import ShopsComponent from "@/components/ShopsComponent.vue";
import ShopsHero from "@/components/ShopsHero.vue";

export default {
  name: "PageView",
  components: {
    ShopsHero,
    ShopsComponent,
    // MetaManager,
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    pageContent() {
      let pageContent = this.$store.getters.getPageContent;
      if (pageContent) {
        if (pageContent.color) {
          this.$store.commit("setGlobalColor", pageContent.color);
        }
      }
      return pageContent;
    },
  },
  created() {
    this.$store.dispatch("fetchShops");
  },
};
</script>

<style></style>
