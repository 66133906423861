<template lang="html">
  <div
    :id="'carousel' + randomId"
    class="carousel slide"
    data-bs-ride="carousel"
    v-if="images.length > 0"
  >
    <div class="carousel-inner">
      <div
        class="carousel-item"
        data-bs-interval="3000"
        v-for="(image, index) in images"
        v-bind:key="image"
        :class="{ active: index === 0 }"
      >
        <div class="ratio" :class="'ratio-' + ratio">
          <img
            :src="getImgUrl() + image.path + '?modified=' + image._modified"
          />
          <div class="text-container p-4 pb-0">
            <p v-html="image.description"></p>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      :data-bs-target="'#carousel' + randomId"
      data-bs-slide="prev"
      v-if="images.length > 1"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      :data-bs-target="'#carousel' + randomId"
      data-bs-slide="next"
      v-if="images.length > 1"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import * as bootstrap from "bootstrap"; // Import Bootstrap explicitly

export default {
  name: "ImageCarousel",
  props: {
    ratio: String,
    images: Array,
  },
  data() {
    return {
      randomId: this.makeid(5),
    };
  },
  mounted() {
    this.initializeCarousel(); // Initialize carousel after the component is mounted
  },
  methods: {
    getImgUrl() {
      return process.env.VUE_APP_IMAGEURL;
    },
    makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    initializeCarousel() {
      const carouselElement = document.getElementById(
        "carousel" + this.randomId
      );
      if (carouselElement) {
        new bootstrap.Carousel(carouselElement, {
          interval: 3000,
          ride: "carousel",
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.ratio img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.text-container {
  display: flex;
  text-align: right;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.text-container p {
  text-shadow: #fff 0 0 10px;
}
</style>
