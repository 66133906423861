<template>
  <div v-if="false">
    <!-- This component does not render anything -->
    <!-- Placeholder for Language Switcher -->
  </div>
</template>
<script>
export default {
  name: "LanguageSwitcher",
  created() {
    // Get the stored preferred language to redirect the user
    let lang = this.$store.getters.getLang;
    this.$i18n.locale = lang;
    this.$router.push("/" + lang);
  },
};
</script>
