<template>
  <li class="nav-item">
    <a class="btn btn-link ps-1 pe-1" href="#" @click="switchLanguage('en')">
      <img
        class="border"
        src="../assets/flags/en.svg"
        alt="english flag"
        height="15px"
      />
    </a>
  </li>
  <li class="nav-item me-2">
    <a class="btn btn-link ps-1 pe-1" href="#" @click="switchLanguage('nl')">
      <img
        class="border"
        src="../assets/flags/nl.svg"
        alt="dutch flag"
        height="15px"
      />
    </a>
  </li>
</template>

<script>
import { generateLocalizedRoute } from "@/helpers/RouteHelper.js";

export default {
  name: "LangSelector",
  computed: {
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    globalTextColor() {
      return this.$store.getters.getGlobalTextColor;
    },
    getFlagPath() {
      return require(`../assets/flags/${this.$i18n.locale}.svg`);
    },
  },
  methods: {
    switchLanguage(targetLocale) {
      const newRoute = generateLocalizedRoute(targetLocale);
      this.$router.push(newRoute);
    },
  },
};
</script>

<style scoped>
/* Navbar Buttons */
.btn-globalcolor,
.btn-globalcolor:active,
.btn-globalcolor:visited {
  background-color: v-bind(globalColor) !important;
  color: v-bind(globalTextColor) !important;
}
.btn-globalcolor:hover {
  opacity: 0.5;
}
.active-globalcolor {
  opacity: 0.7;
}
</style>
