<template>
  <section class="pt-5 pb-5">
    <div class="container p-0">
      <div class="row">
        <ProductCard
          v-for="product in getProducts"
          v-bind:key="product"
          :product="product"
        />
      </div>
      <PaginationNav />
    </div>
  </section>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import PaginationNav from "./PaginationNav.vue";

export default {
  name: "ProductsListComponent",
  components: {
    ProductCard,
    PaginationNav,
  },
  computed: {
    globalColor() {
      return this.$store.getters.getGlobalColor;
    },
    getProducts() {
      return this.$store.getters.getProducts;
    },
  },
  methods: {
    hexToRgb(hex) {
      // Remove the hash (#) if it exists
      hex = hex.replace(/^#/, "");

      // Handle shorthand HEX codes (e.g., #FFF)
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((char) => char + char)
          .join("");
      }

      // Parse the HEX values to integers
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `${r}, ${g}, ${b}`;
    },
  },
};
</script>

<style scoped></style>
