<template>
  <nav v-if="totalPages > 1">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: currentPage <= 1 }">
        <a
          class="page-link"
          href="#"
          @click.prevent="changePage(currentPage - 1)"
          onclick="this.blur();"
        >
          <i class="bi bi-chevron-left"></i>
        </a>
      </li>
      <li
        v-for="pageNumber in totalPages"
        :key="pageNumber"
        class="page-item"
        :class="{ active: pageNumber === currentPage }"
      >
        <a
          class="page-link"
          href="#"
          @click.prevent="changePage(pageNumber)"
          :class="{ 'text-white': pageNumber === currentPage }"
          onclick="this.blur();"
        >
          {{ pageNumber }}
        </a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage >= totalPages }">
        <a
          class="page-link"
          href="#"
          @click.prevent="changePage(currentPage + 1)"
          onclick="this.blur();"
        >
          <i class="bi bi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "PaginationNav",
  computed: {
    totalPages() {
      let items = this.$store.getters.getTotalItems;
      let limit = this.$store.getters.getLimit;
      if (limit < items) {
        return Math.ceil(items / limit);
      }
      return 1;
    },
    currentPage() {
      let limit = this.$store.getters.getLimit;
      let skip = this.$store.getters.getSkip;
      let page = skip / limit;
      return page + 1;
    },
  },
  methods: {
    changePage(newPageNumber = null) {
      const { locale, type, tag, pageNumber } = this.$route.params;
      const isEnglish = locale === "en";

      // Ensure 'type' is always valid ('products' or 'producten')
      const validType = type || (isEnglish ? "products" : "producten");

      // Start building the new path
      let newPath = `/${locale}/${validType}`;

      // Append the category if it exists
      if (tag) {
        newPath += `/${tag}`;
      }

      // Only include page/pagina if newPageNumber is provided
      if (newPageNumber) {
        newPath += `/${isEnglish ? "page" : "pagina"}/${newPageNumber}`;
      }
      if (!pageNumber) {
        // Reset the page number if it's missing in the current route
        newPath += ``;
        this.$store.commit("setPage", 1);
      }

      // Navigate to the new path
      this.$router.push(newPath);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped></style>
